import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Premium from "./pages/Premium";
import Layout from "./pages/Layout";
import Enquiry from "./pages/Enquiry";
import PremiumListing from "./pages/PremiumListing";
import Orders from "./pages/Orders";
import { Coupons, Customer, Customers } from "./pages";
import Index from "./pages/Dashboard/Index";
import Commission from "./pages/Commission";

function Routing() {
  return (
    <div>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="customers" element={<Customers />} />
          <Route path="customer/:id" element={<Customer />} />
          <Route path="order/:id" element={<Customer />} />
          <Route path="premiumlisting/:number" element={<PremiumListing />} />
          <Route path="orders" element={<Orders />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="premiums" element={<Premium />} />
          <Route path="commission" element={<Commission/>} />
        </Route>
      </Routes>
    </div>
  );
}

export default Routing;
