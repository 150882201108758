import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Card from "./components/Card";
import axios from "axios";
import { apiRequest } from "../../utils/api";
import Table from "./components/Table";

function Index() {
  const [totals, setTotals] = useState();
  const [premium, setPremium] = useState([]);

  useEffect(() => {
    getDashboard();
    getPremiums();
    document.title = `Dashboard | JDM Plates`;
  }, []);
  async function getDashboard() {
    try {
      let res = await apiRequest.get(`/dashboard/totals`);
      if (res.data.status === 200) {
        setTotals(res.data.message);
      } else {
        setTotals();
        console.log(res.data.message);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }
  async function getPremiums() {
    try {
      let res = await apiRequest.get(`premium/seller/all`);
      if (res.data.status === 200) {
        setPremium(res.data.message);
      } else {
        setPremium();
        console.log(res.data.message);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title">
            <h2>Dashboard</h2>
          </div>
        </div>
        <div className="dashboard-card-row">
          <div className="row">
            <Card
              image={"IoWallet"}
              title={"Total Revenue"}
              value={`${totals?.revenue ? "£" + parseFloat(totals?.revenue).toFixed(2) : 0}`}
            />
            <Card
              image={"shoppingCart"}
              title={"Registered Users"}
              value={totals?.customer}
            />
            <Card image={"car"} title={"Plates Sold"} value={totals?.sales} />
            <Card image={"email"} title={"Enquiries"} value={totals?.bids} />
          </div>
        </div>
        {/* <div className="dashboard-tabel">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-tabel">
                <h2>Recent Orders</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="datatable0">
                <DataTable columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div> */}
        <div className="dashboard-tabel">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-tabel">
                <h2>Premium Number Plate </h2>
              </div>
            </div>
            <div className="col-md-12">
              <Table plates={premium} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
