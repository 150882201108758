import React from "react";
import Routing from "./Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Sidebar from "./utils/Sidebar";
import { useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();
  localStorage.setItem("api", "52.56.84.241:8080");

  return (
    <div>
      {/* {pathname != "/" && <Sidebar />} */}

      <Routing />
    </div>
  );
}

export default App;
