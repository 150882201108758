import React, { useEffect, useState } from "react";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { addStorage, getStorageValue, removeStorage } from "../utils/storage";
import { apiRequest } from "../utils/api";

function Layout() {
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu2 = () => {
    if (width < 767) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    matchToken();
  }, []);

  async function matchToken() {
    try {
      let res = await apiRequest.post(`/admin-user/match-token`, {
        token: getStorageValue("token"),
      });
      if (res.data.status === 200) {
        addStorage("email", res.data.message.email);
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  }

  async function logout() {
    removeStorage();
    navigate("/login");
  }

  console.log(pathname);
  return (
    <div>
      <div className="page-wraper">
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="sidebar-logo">
            <img src="/img/logo.svg" />
          </div>
          <ul className="sidebar-nav">
            <li>
              <Link
                onClick={toggleMenu2}
                className={pathname == "/" ? "active" : null}
                to={"/"}
              >
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.91 16.19C9.91 16.83 9.39 17.35 8.74 17.35C8.1 17.35 7.58 16.83 7.58 16.19V12.93C7.58 12.29 8.1 11.77 8.74 11.77C9.39 11.77 9.91 12.29 9.91 12.93V16.19ZM16.42 16.19C16.42 16.83 15.9 17.35 15.26 17.35C14.61 17.35 14.09 16.83 14.09 16.19V7.81C14.09 7.17 14.61 6.65 15.26 6.65C15.9 6.65 16.42 7.17 16.42 7.81V16.19Z"
                      fill="white"
                    />
                  </svg>
                </span>
                dashboard
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu2}
                className={
                  pathname == "/premiums" ||
                  pathname.indexOf("/premiumlisting") >= 0
                    ? "active"
                    : null
                }
                to={"/premiums"}
              >
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.75 3.5V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C7.5 3.53 7.73 3.5 8 3.5H8.75Z"
                      fill="white"
                    />
                    <path
                      d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H16C16.27 3.5 16.5 3.53 16.75 3.56Z"
                      fill="white"
                    />
                    <path
                      d="M16.75 3.56V5C16.75 5.41 16.41 5.75 16 5.75C15.59 5.75 15.25 5.41 15.25 5V3.5H8.75V5C8.75 5.41 8.41 5.75 8 5.75C7.59 5.75 7.25 5.41 7.25 5V3.56C4.3 3.83 3 5.73 3 8.5V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V8.5C21 5.73 19.7 3.83 16.75 3.56ZM12 16.75H8C7.59 16.75 7.25 16.41 7.25 16C7.25 15.59 7.59 15.25 8 15.25H12C12.41 15.25 12.75 15.59 12.75 16C12.75 16.41 12.41 16.75 12 16.75ZM16 11.75H8C7.59 11.75 7.25 11.41 7.25 11C7.25 10.59 7.59 10.25 8 10.25H16C16.41 10.25 16.75 10.59 16.75 11C16.75 11.41 16.41 11.75 16 11.75Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Premium Listing{" "}
              </Link>
            </li>

            <li>
              <Link
                onClick={toggleMenu2}
                className={
                  pathname == "/customers" || pathname.indexOf("/customer") >= 0
                    ? "active"
                    : null
                }
                to={"/customers"}
              >
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19 3.29 20.93 5.56 21.66C6.22 21.89 6.98 22 7.81 22H16.19C17.02 22 17.78 21.89 18.44 21.66C20.71 20.93 22 19 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM20.5 16.19C20.5 18.33 19.66 19.68 17.97 20.24C17 18.33 14.7 16.97 12 16.97C9.3 16.97 7.01 18.32 6.03 20.24H6.02C4.35 19.7 3.5 18.34 3.5 16.2V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V16.19Z"
                      fill="white"
                    />
                    <path
                      d="M11.9999 8C10.0199 8 8.41992 9.6 8.41992 11.58C8.41992 13.56 10.0199 15.17 11.9999 15.17C13.9799 15.17 15.5799 13.56 15.5799 11.58C15.5799 9.6 13.9799 8 11.9999 8Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Customers
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu2}
                className={
                  pathname == "/orders" || pathname.indexOf("/order") >= 0
                    ? "active"
                    : null
                }
                to={"/orders"}
              >
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 11.46V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.46C22 10.79 21.46 10.25 20.79 10.25H3.21C2.54 10.25 2 10.79 2 11.46ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                      fill="white"
                    />
                    <path
                      d="M13.5 4.6099V7.5399C13.5 8.2099 12.96 8.7499 12.29 8.7499H3.21C2.53 8.7499 2 8.1899 2 7.5199C2.01 6.3899 2.46 5.3599 3.21 4.6099C3.96 3.8599 5 3.3999 6.14 3.3999H12.29C12.96 3.3999 13.5 3.9399 13.5 4.6099Z"
                      fill="white"
                    />
                    <path
                      d="M19.97 2H17.03C15.76 2 15 2.76 15 4.03V6.97C15 8.24 15.76 9 17.03 9H19.97C21.24 9 22 8.24 22 6.97V4.03C22 2.76 21.24 2 19.97 2ZM20.63 4.45L18.26 7.22C18.17 7.33 18.03 7.4 17.89 7.4C17.88 7.4 17.88 7.4 17.87 7.4C17.73 7.4 17.6 7.35 17.5 7.25L16.4 6.17C16.2 5.97 16.2 5.64 16.4 5.44C16.6 5.24 16.93 5.23 17.13 5.44L17.83 6.13L19.83 3.79C20.02 3.57 20.34 3.55 20.56 3.73C20.79 3.91 20.81 4.24 20.63 4.45Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Orders
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu2}
                className={
                  pathname == "/commission" ||
                  pathname.indexOf("/commission") >= 0
                    ? "active"
                    : null
                }
                to={"/commission"}
              >
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.4499 13.6399V14.6399C21.4499 14.9099 21.2399 15.1299 20.9599 15.1399H19.4999C18.9699 15.1399 18.4899 14.7499 18.4499 14.2299C18.4199 13.9199 18.5399 13.6299 18.7399 13.4299C18.9199 13.2399 19.1699 13.1399 19.4399 13.1399H20.9499C21.2399 13.1499 21.4499 13.3699 21.4499 13.6399Z"
                      fill="white"
                    />
                    <path
                      d="M17.9902 12.6899C17.4902 13.1799 17.2502 13.9099 17.4502 14.6699C17.7102 15.5999 18.6202 16.1899 19.5802 16.1899H20.4502C21.0002 16.1899 21.4502 16.6399 21.4502 17.1899V17.3799C21.4502 19.4499 19.7602 21.1399 17.6902 21.1399H6.2102C4.1402 21.1399 2.4502 19.4499 2.4502 17.3799V10.6499C2.4502 9.41989 3.0402 8.32989 3.9502 7.64989C4.5802 7.16989 5.3602 6.88989 6.2102 6.88989H17.6902C19.7602 6.88989 21.4502 8.57989 21.4502 10.6499V11.0899C21.4502 11.6399 21.0002 12.0899 20.4502 12.0899H19.4302C18.8702 12.0899 18.3602 12.3099 17.9902 12.6899Z"
                      fill="white"
                    />
                    <path
                      d="M16.2001 4.82C16.4701 5.09 16.2401 5.51 15.8601 5.51L8.18006 5.5C7.74006 5.5 7.51006 4.96 7.83006 4.65L9.45006 3.02C10.8201 1.66 13.0401 1.66 14.4101 3.02L16.1601 4.79C16.1701 4.8 16.1901 4.81 16.2001 4.82Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Commission{" "}
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu2}
                className={
                  pathname == "/coupons" || pathname.indexOf("/coupons") >= 0
                    ? "active"
                    : null
                }
                to={"/coupons"}
              >
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 10.75C22.41 10.75 22.75 10.41 22.75 10V9C22.75 4.59 21.41 3.25 17 3.25H10.75V5.5C10.75 5.91 10.41 6.25 10 6.25C9.59 6.25 9.25 5.91 9.25 5.5V3.25H7C2.59 3.25 1.25 4.59 1.25 9V9.5C1.25 9.91 1.59 10.25 2 10.25C2.96 10.25 3.75 11.04 3.75 12C3.75 12.96 2.96 13.75 2 13.75C1.59 13.75 1.25 14.09 1.25 14.5V15C1.25 19.41 2.59 20.75 7 20.75H9.25V18.5C9.25 18.09 9.59 17.75 10 17.75C10.41 17.75 10.75 18.09 10.75 18.5V20.75H17C21.41 20.75 22.75 19.41 22.75 15C22.75 14.59 22.41 14.25 22 14.25C21.04 14.25 20.25 13.46 20.25 12.5C20.25 11.54 21.04 10.75 22 10.75ZM10.75 14.17C10.75 14.58 10.41 14.92 10 14.92C9.59 14.92 9.25 14.58 9.25 14.17V9.83C9.25 9.42 9.59 9.08 10 9.08C10.41 9.08 10.75 9.42 10.75 9.83V14.17Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Coupons{" "}
              </Link>
            </li>

            <li>
              <a onClick={logout} style={{ cursor: "pointer" }}>
                <span>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z"
                      fill="white"
                    />
                    <path
                      d="M4.56043 11.2501L6.63043 9.18009C6.78043 9.03009 6.85043 8.84009 6.85043 8.65009C6.85043 8.46009 6.78043 8.26009 6.63043 8.12009C6.34043 7.83009 5.86043 7.83009 5.57043 8.12009L2.22043 11.4701C1.93043 11.7601 1.93043 12.2401 2.22043 12.5301L5.57043 15.8801C5.86043 16.1701 6.34043 16.1701 6.63043 15.8801C6.92043 15.5901 6.92043 15.1101 6.63043 14.8201L4.56043 12.7501H9.00043V11.2501H4.56043V11.2501Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Logout
              </a>
            </li>
          </ul>
        </div>

        <div className={`page-wraper-content ${isOpen ? "open" : ""}`}>
          <div className="menu-toggle" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
